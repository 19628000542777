<template>
  <div class="create">
      <CRow>
        <CCol md="12">
          <CCard>
           <form id="UpdateCustomer" @submit="UpdateCustomer" >
           <CCardHeader>
            <CCol col="6" class="styleHeader"> {{ $t('message.updateCustomer') }}</CCol>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
             <CCol class="mb-3">
                      <label for='Customer'>
                       {{ $t('message.customerType') }}
                      <span class='star'>*</span>
                    </label>
                    <multiselect
                        class='required'
                        v-model='selectedTypeOfCustomer'
                        id='type_customer_id'
                        :options='typeOfCustomers'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='id'
                        required='required'
                      >
                        <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                        <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect>
              </CCol>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
               <CInput :label="$t('message.name')"
                  id="name"
                  v-model="customer.name"
                  class="required"
                  :placeholder="$t('message.name')"
                  :invalidFeedback="errors.email"
                  required
                />
              </CCol>
              <CCol sm="6">
                 <CInput :label="$t('message.email')"
                  id="email"
                  v-model="customer.email"
                  class=""
                  :placeholder="$t('message.email')"
                  :invalidFeedback="errors.email"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
              <CInput :label="$t('message.phone')"
                  id="phone"
                  v-model="customer.phone"
                  class="required"
                  :placeholder="$t('message.phone')"
                  :invalidFeedback="errors.phone"
                  required
                />
              </CCol>
               <CCol sm="6">
                <CInput :label="$t('message.address')"
                  id="address"
                  v-model="customer.address"
                  :placeholder="$t('message.address')"
                  :invalidFeedback="errors.address"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput :label="$t('message.zain')"
                  id="zain_transfer_no"
                  v-model="customer.zain_transfer_no"
                  :placeholder="$t('message.zain')"
                  :invalidFeedback="errors.zain_transfer_no"
                />
              </CCol>
              <CCol sm="6">
                <CInput :label="$t('message.mtn')"
                  id="mtn_transfer_no"
                  v-model="customer.mtn_transfer_no"
                  :placeholder="$t('message.mtn')"
                  :invalidFeedback="errors.mtn_transfer_no"
                />
              </CCol>
            </CRow>
             <CRow>
              <CCol sm="6">
                <CInput :label="$t('message.digital')"
                  id="digitel_transfer_no"
                  v-model="customer.digitel_transfer_no"
                  :placeholder="$t('message.digital')"
                  :invalidFeedback="errors.digitel_transfer_no"
                />
              </CCol>
              <CCol sm="6">
                <CInput :label="$t('message.mgurush')"
                  id="mgurush_transfer_no"
                  v-model="customer.mgurush_transfer_no"
                  :placeholder="$t('message.mgurush')"
                  :invalidFeedback="errors.mgurush_transfer_no"
                />
              </CCol>
             </CRow>
             <CRow >
              <CCol sm="6">
                <CInput :label="$t('message.momo')"
                  id="momo_transfer_no"
                  v-model="customer.momo_transfer_no"
                  :placeholder="$t('message.momo')"
                  :invalidFeedback="errors.momo_transfer_no"
                />
              </CCol>
            </CRow>

          </CCardBody>
             <CCardFooter>
            <CButton type="submit" size="sm" color="primary" >
            <CIcon name="cil-check-circle"/> {{ $t('message.save')}}</CButton>
              &emsp;
         <CButton type="button" size="sm" color="btn btn-primary"  @click="goBack">
            <CIcon name="cil-ban"/> {{ $t('message.back') }} </CButton>
          </CCardFooter>
             </Form>
           </CCard>
        </CCol>
      </CRow>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'EditCustomer',
  data: function () {
    return {
      customer: [],
      selectedTypeOfCustomer: ' ',
      errors: [],
      typeOfCustomers: []
    }
  },
  created () {
    const typeOfCustomers = []
    this.$http
      .get(`${this.$hostUrl}customer-type`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          typeOfCustomers.push({ id: value.id, name: value.name })
        })
        this.typeOfCustomers = typeOfCustomers
      })
    this.$http
      .get(`${this.$hostUrl}customers/${this.$route.params.id}`)
      .then((response) => {
        this.customer = response.data.data.customer
        this.selectedTypeOfCustomer = {
          id: this.customer.type_customer.id,
          name: this.customer.type_customer.name
        }
      })
  },
  methods: {
    async UpdateCustomer (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('name', this.customer.name)
      if (this.customer.email) {
        formData.append('email', this.customer.email)
      }
      formData.append('phone', this.customer.phone)
      if (this.customer.address) {
        formData.append('address', this.customer.address)
      }
      if (this.customer.zain_transfer_no) {
        formData.append('zain_transfer_no', this.customer.zain_transfer_no)
      }
      if (this.customer.mtn_transfer_no) {
        formData.append('mtn_transfer_no', this.customer.mtn_transfer_no)
      }
      if (this.customer.sudani_transfer_no) {
        formData.append('sudani_transfer_no', this.customer.sudani_transfer_no)
      }
      if (this.customer.digitel_transfer_no) {
        formData.append('digitel_transfer_no', this.customer.digitel_transfer_no)
      }
      if (this.customer.mgurush_transfer_no) {
        formData.append('mgurush_transfer_no', this.customer.mgurush_transfer_no)
      }
      if (this.customer.momo_transfer_no) {
        formData.append('momo_transfer_no', this.customer.momo_transfer_no)
      }

      formData.append('user_id', 1)
      formData.append('type_customer_id', this.selectedTypeOfCustomer.id)
      formData.append('_method', 'PUT')

      this.errors = await this.postRequest(formData, `${this.$hostUrl}customers/${this.$route.params.id}`, '/customers')
    },
    goBack () {
      this.workersceOpened ? this.$router.go(-1) : this.$router.push({ path: '/customers' })
    }
  }
}
</script>
